import "react-loadingmask/dist/react-loadingmask.css";
import "react-day-picker/lib/style.css";
import "moment/locale/th";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useEffect, useRef, useState } from "react";
import {
  api,
  header_token,
  img_url,
  rider_status_option,
  selectStyle,
} from "../../config";

import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { ExportRiderData } from "./ExportRiderExcel";
import { Link } from "react-router-dom";
import LoadingMask from "react-loadingmask";
import ReactPaginate from "react-paginate";
import { RiderHead } from "./RiderHead";
import Select from "react-select";
import { checkImageFlowOld } from '../../utils/Function'
import moment from "moment";
import swal from "sweetalert";

const action_option = [{ value: 0, label: "ลบ" }];

export const RiderAll = () => {
  const checkAllRef = useRef(null);

  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);

  const [img, setimg] = useState("");
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_name, setfilter_name] = useState("");
  const [filter_status, setfilter_status] = useState({
    value: "",
    label: "เลือก..",
  });
  const [filter_area, setfilter_area] = useState({
    value: "",
    label: "ทั้งหมด",
  });
  const [filter_team, setfilter_team] = useState({
    value: "",
    label: "ทั้งหมด",
  });
  const [filter_type, setfilter_type] = useState({
    value: "",
    label: "ทั้งหมด",
  });

  const [action, setaction] = useState({ value: "", label: "เลือก.." });

  const [option_area, setoption_area] = useState([]);
  const [option_employee_type, setoption_employee_type] = useState([]);
  const [option_team, setoption_team] = useState([]);
  const [bank_option, setbank_option] = useState([]);

  const [add_name, setadd_name] = useState("");
  const [bank, setbank] = useState("");
  const [rider_id, setrider_id] = useState("");
  const [credit_amount, setcredit_amount] = useState(1);
  const [note, setnote] = useState("");

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);

  const exportexcel = async () => {
    setexport_loading(true);

    try {
      let send_data = {
        area_id: filter_area.value,
        employee_type_id: filter_type.value,
        search: filter_name,
        status: filter_status.value === "" ? -1 : parseInt(filter_status.value),
        team_id: filter_team.value,
        role: "rider",
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${999999999}`,
        send_data,
        header_token
      );

      // console.log(data);

      let _export = [];
      if (data.users) {
        data.users.forEach((value, index) => {
          let _area = "";

          if (value.area) {
            value.area.forEach((v, idx) => {
              let comma = "";
              if (idx !== value.area.length - 1) comma = ", ";
              _area += v.name_th + comma;
            });
          }

          let st;

          switch (value.status) {
            case 0:
              st = "ระงับ";
              break;
            case 1:
              st = "ใช้งาน";
              break;
            case 2:
              st = "ไม่อนุมัติ";
              break;
            case 3:
              st = "รออนุมัติ";
              break;

            default:
              break;
          }

          _export.push({
            name: value.full_name,
            identification_number: value.identification_number,
            birth_date: moment(value.birth_date).format("DD/MM/YYYY"),
            degree: value.degree.name_th,
            address: value.location.address,
            sub_district: value.location.sub_district.name_th,
            district: value.location.district.name_th,
            province: value.location.province.name_th,
            zip_code: value.location.sub_district.zip_code,
            email: value.email,
            phone_number: value.phone_number,
            area: _area,
            team: value.team.name_th,
            line_id: value.line_id,
            menu: value.menu_count,
            credit: value.credit,
            point: value.point,
            employee_type: value.employee_type.name_th,
            status: st,
            vehicle_type: value.vehicle_type.name_th,
            vehicle_make: value.vehicle_make,
            vehicle_color: value.vehicle_color,
            license_plate: value.license_plate,
            register: moment(value.register_date).format("DD/MM/YYYY"),
          });
        });
      }

      // console.log("_export", _export);
      setexport_data(_export);
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }

    setexport_loading(false);
  };

  const getBank = async () => {
    try {
      let { data } = await Axios.get(`${api}/bank`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setbank_option(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_area(tmp);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getOptionTeam = async () => {
    try {
      let { data } = await Axios.get(`${api}/teams`);
      // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_team(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionEmployee = async () => {
    try {
      let { data } = await Axios.get(`${api}/employee-type`);
      // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_employee_type(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    setloading(true);
    try {
      let send_data = {
        area_id: filter_area.value,
        employee_type_id: filter_type.value,
        search: filter_name,
        status: filter_status.value === "" ? -1 : parseInt(filter_status.value),
        team_id: filter_team.value,
        role: "rider",
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      let cal_page_count = Math.ceil(data.meta.total_count / limit);
      setpage_count(cal_page_count);
      settotal_count(data.meta.total_count);

      setdata(data.users);
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setloading(false);
  };

  const deleteRider = async (rider_id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/user/profile/${rider_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error.response);
      return false;
    }
  };

  const addCredit = async (user_id) => {
    if (credit_amount && bank) {
      try {
        let send_data = {
          amount: credit_amount,
          bank_id: bank.value,
          picture: "",
          remark: note,
          transaction_date: `${moment().format("YYYY-MM-DD")} ${moment().format(
            "LTS"
          )}`,
        };

        // console.log("send_data", send_data);
        let res = await Axios.post(
          `${api}/admin/wallet/deposit/${user_id}?status=1`,
          send_data,
          header_token
        );

        // console.log(res);
        if (res.status === 201) {
          swal("เติมเครดิตเสร็จสิ้น !!", {
            icon: "success",
          });
          getData();
          setcredit_amount(1);
          setnote("");
          setbank("");
          return true;
        } else {
          return false;
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
        return false;
      }
    } else {
      alert("ข้อมูลไม่ครบ");
    }
  };

  useEffect(() => {
    getData();
    getOptionArea();
    getOptionTeam();
    getOptionEmployee();
    getBank();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
    // eslint-disable-next-line
  }, [filter_area, filter_team, filter_name, filter_type, filter_status]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [current_page]);

  return (
    <div>
      <RiderHead />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">
                    ทั้งหมด {total_count.toLocaleString()} รายการ
                  </h4>
                </div>

                <div>
                  <div className="form-row align-items-center mb-2 mr-2 ">
                    <div className=" mr-2" style={{ width: 200 }}>
                      <span className="tx-13 text-muted">ค้นหา</span>
                      <input
                        type="text"
                        className="form-control- global_filter"
                        id="global_filter"
                        placeholder="ชื่อ / เบอร์โทร / ทะเบียน"
                        value={filter_name}
                        onChange={(e) => {
                          setfilter_name(e.target.value);
                        }}
                      />
                    </div>

                    <div className="ml-2 mr-3">
                      <div className="row">
                        <div style={{ width: 120 }}>
                          <span className="tx-13 text-muted">ทีมงาน</span>
                          <Select
                            styles={selectStyle}
                            value={filter_team}
                            onChange={(e) => {
                              setfilter_team(e);
                            }}
                            options={option_team}
                            placeholder={"ทีมงาน.."}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="ml-2 mr-3">
                      <div className="row">
                        <div style={{ width: 120 }}>
                          <span className="tx-13 text-muted">พื้นที่</span>
                          <Select
                            styles={selectStyle}
                            value={filter_area}
                            onChange={(e) => {
                              setfilter_area(e);
                            }}
                            options={option_area}
                            placeholder={"พื้นที่.."}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="ml-2 mr-3">
                      <div className="row">
                        <div style={{ width: 120 }}>
                          <span className="tx-13 text-muted">ประเภท</span>
                          <Select
                            styles={selectStyle}
                            value={filter_type}
                            onChange={(e) => {
                              setfilter_type(e);
                            }}
                            options={option_employee_type}
                            placeholder={"ประเภท.."}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="ml-2 mr-3">
                      <div className="row">
                        <div style={{ width: 120 }}>
                          <span className="tx-13 text-muted">สถานะ</span>
                          <Select
                            styles={selectStyle}
                            value={filter_status}
                            onChange={(e) => {
                              setfilter_status(e);
                            }}
                            options={rider_status_option}
                            placeholder={"ประเภท.."}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col ml-1">
                      <div className="row">
                        <div style={{ width: 120 }}>
                          <span className="tx-13 text-muted">ดำเนินการ</span>
                          <Select
                            styles={selectStyle}
                            value={action}
                            onChange={(e) => {
                              setaction(e);
                            }}
                            options={action_option}
                            placeholder={"ดำเนินการ.."}
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ width: 130 }}>
                      <button
                        type="button"
                        className="btn btn-primary ml-3"
                        style={{ marginTop: 20 }}
                        onClick={async () => {
                          let count = data.filter((e) => e.checked === true);
                          if (action.value === 0 && count.length > 0) {
                            swal({
                              title: `ต้องการลบพนักงานขับรถที่เลือกหรือไม่ ?`,
                              confirmButtonText: "ลบ",
                              cancelButtonText: "ยกเลิก",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) {
                                let sending = [];
                                data.forEach((value) => {
                                  if (value.checked === true) {
                                    sending.push(deleteRider(value.id));
                                  }
                                });

                                let is_success = true;
                                await Promise.all(sending).then((res) => {
                                  // console.log(res);
                                  if (
                                    res.filter((e) => e === true).length ===
                                    0 ||
                                    res.length === 0
                                  )
                                    is_success = false;
                                });

                                if (is_success) {
                                  getData();
                                  swal("ลบเสร็จสิ้น !!", {
                                    icon: "success",
                                  });
                                } else {
                                  swal("เกิดข้อผิดพลาด !!", {
                                    icon: "error",
                                  });
                                }
                              }
                            });
                          } else {
                            alert("เลือกตัวดำเนินการ");
                          }
                        }}
                      >
                        ดำเนินการ
                      </button>
                    </div>
                    <div style={{ maxWidth: 130 }}>
                      <button
                        type="button"
                        className="btn btn-primary mr-2"
                        style={{ marginTop: 23 }}
                        onClick={async () => {
                          if (!trigger && !export_loading) {
                            await exportexcel();
                            await settrigger(true);
                          }
                        }}
                      >
                        {export_loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                        {` `}Export
                      </button>
                      <ExportRiderData
                        data={export_data}
                        trigger={trigger}
                        settrigger={settrigger}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="sticky-div">
                    <table id="dataTable" className="table sticky-table">
                      <thead>
                        <tr>
                          <th style={{ width: 30 }} className="sticky-head">
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                // console.log(checkAllRef.current.checked);
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, idx) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });
                                setdata(_tmp);
                                // console.log(data);
                              }}
                            />
                          </th>
                          <th style={{ width: 100 }} className="sticky-head">
                            รูป
                          </th>
                          <th style={{ width: 80 }} className="sticky-head">
                            รหัส
                          </th>
                          <th style={{ width: 250 }} className="sticky-head">
                            ชื่อ-นามสกุล
                          </th>
                          <th style={{ width: 120 }}>วันที่สมัคร</th>
                          <th style={{ width: 150 }}>เบอร์โทร</th>
                          <th style={{ width: 100 }}>ID LIne</th>
                          <th style={{ width: 200 }}>พื้นที่</th>
                          <th style={{ width: 140 }}>เครดิต</th>
                          <th style={{ width: 100 }}>แต้ม</th>
                          <th style={{ width: 150 }}>ประเภท</th>
                          <th style={{ width: 120 }}>พาหนะ</th>
                          <th style={{ width: 120 }}>ทะเบียน</th>
                          <th style={{ width: 120 }}>อุปกรณ์</th>
                          <th style={{ width: 80 }}>สถานะ</th>
                          <th style={{ width: 130 }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          const isShowOldFlow = checkImageFlowOld(value.picture)

                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked ? "#e8eaed" : "",
                              }}
                            >
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                <input
                                  type="checkbox"
                                  checked={value.checked}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : isShowOldFlow ? `${img_url}${value.picture}` : value.picture
                                    );
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="lazy img-responsive"
                                    src={
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : isShowOldFlow ? `${img_url}${value.picture}` : value.picture
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = "/images/no-img.jpg";
                                    }}
                                  />
                                </div>
                              </td>
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                {value.user_id}
                              </td>
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                <Link to={`/rider/history/${value.id}`}>
                                  <span>{value.full_name}</span>
                                </Link>
                              </td>
                              <td>
                                {moment(value.register_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>{value.phone_number}</td>
                              <td>{value.line_id}</td>
                              <td>
                                {value.area
                                  ? value.area.map((val, inx) => {
                                    return (
                                      <span key={inx}>{val.name_th}, </span>
                                    );
                                  })
                                  : ""}
                              </td>
                              <td className="text-success">
                                <div>฿{value.credit.toLocaleString()}</div>
                              </td>
                              <td>{value.point}</td>
                              <td>{value.employee_type.name_th}</td>
                              <td>{value.vehicle_type.name_th}</td>
                              <td>{value.license_plate}</td>
                              <td>{value.rider_equipment === null ? 'ไม่มีอุปกรณ์' :
                                value.rider_equipment.map((eq, index) => {
                                  return eq.name_th + `${value.rider_equipment.length - 1 !== index ? ', ' : ""}`
                                })
                              }</td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return (
                                        <div className="badge badge-warning">
                                          ระงับ
                                        </div>
                                      );
                                    case 1:
                                      return (
                                        <div className="badge badge-success">
                                          ใช้งาน
                                        </div>
                                      );

                                    case 2:
                                      return (
                                        <div className="badge badge-danger">
                                          ไม่อนุมัติ
                                        </div>
                                      );
                                    case 3:
                                      return (
                                        <div className="badge badge-primary">
                                          รออนุมัติ
                                        </div>
                                      );

                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              {/* <td>
                                <Link to={`/rider/service/${value.id}`}>
                                  <button className="btn btn-outline-primary btn-sm mb-1">
                                    เพิ่มเติม
                                  </button>
                                </Link>
                              </td> */}
                              <td>
                                <Link to={`/rider/history/${value.id}`}>
                                  <button className="btn btn-outline-primary btn-sm mb-1">
                                    รายละเอียด
                                  </button>
                                </Link>
                                <button
                                  data-toggle="modal"
                                  data-target={`.credit-add`}
                                  className="btn btn-outline-primary btn-sm mb-1"
                                  onClick={() => {
                                    setrider_id(value.id);
                                    setadd_name(value.full_name);
                                    setcredit_amount(1);
                                    setnote("");
                                    setbank("");
                                  }}
                                >
                                  เติมเครดิต
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
              <div className="mt-2" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={page_count}
                  onPageChange={(e) => setcurrent_page(e.selected + 1)}
                  forcePage={current_page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                alt=""
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/images/no-img.jpg";
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className={`modal  credit-add`} role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>เติมเครดิต</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="add_name">พนักงาน</label>
                </div>
                <div className="col-lg-5">
                  <input
                    id="add_name"
                    className="form-control"
                    name="add_name"
                    type="text"
                    value={add_name}
                    onChange={(e) => setadd_name(e.target.value)}
                    readOnly
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="bank">ธนาคาร</label>
                </div>
                <div className="col-lg-5">
                  <Select
                    value={bank}
                    onChange={(e) => {
                      setbank(e);
                    }}
                    options={bank_option}
                    placeholder={"ธนาคาร.."}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="credit_amount">จำนวนเงิน(บาท)</label>
                </div>
                <div className="col-lg-5">
                  <input
                    id="credit_amount"
                    className="form-control"
                    name="credit_amount"
                    type="number"
                    placeholder="บาท"
                    onChange={(e) => {
                      if (e.target.value > 0) {
                        setcredit_amount(parseInt(e.target.value));
                      }
                    }}
                    value={credit_amount}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="name">วันที่</label>
                </div>
                <div className="col-lg-5">
                  <div className="input-group input-daterange d-flex align-items-center">
                    <DayPickerInput
                      value={new Date()}
                      placeholder={"วัน/เดือน/ปี"}
                      style={{ width: "100%" }}
                      inputProps={{
                        style: {
                          width: "100%",
                          padding: 5,
                        },
                        disabled: true,
                      }}
                      format="L"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      dayPickerProps={{
                        locale: "th",
                        localeUtils: MomentLocaleUtils,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="name">หมายเหตุ</label>
                </div>
                <div className="col-lg-5">
                  <input
                    id="name"
                    className="form-control"
                    name="name"
                    type="text"
                    value={note}
                    onChange={(e) => setnote(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mr-2 mb-4">
                <div className="col-12">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      onClick={() => {
                        addCredit(rider_id);
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
